import React from 'react';
import { FaTelegram, FaTwitter, FaDiscord } from 'react-icons/fa';
import './App.css';

function App() {
  return (
    <div className="App">
      {/* Navbar */}
      <nav className="navbar">
        <h1 className="logo">Buzz Hive</h1>
        
        <div className="social-icons">
          <FaTelegram />
          <FaTwitter />
          <FaDiscord />
        </div>
      </nav>

      {/* Story Section */}
      <section className="story">
        <div className="story-content">
          <h2>Our Story</h2>
          <p>
            Welcome to Buzz Hive! Our mission is to bring the sweetness of crypto to everyone. 
            Starting from humble beginnings, we've created a unique meme coin experience centered 
            around the buzz and community spirit. Join us on our journey as we aim to make waves in 
            the crypto world, just like bees working together to build a hive. 
          </p>
        </div>
      </section>

      {/* Tokenomics Section */}
      <section className="tokenomics">
        <h2>Tokenomics</h2>
        <div className="honeycomb">
          <div className="hex">Supply: 1 Billion</div>
          <div className="hex">Liquidity: Burned</div>
          <div className="hex">Mint, Freeze: Revoked</div>
        </div>
      </section>

      {/* Roadmap Section */}
      <section className="roadmap">
        <h2>Roadmap</h2>
        <div className="road">
          <div className="road-item">Phase 1: Launch</div>
          <div className="road-item">Phase 2: Listings</div>
          <div className="road-item">Phase 3: Community</div>
          <div className="road-item">Phase 4: Expansion</div>
        </div>
      </section>
    </div>
  );
}

export default App;
